<template>
  <insideHeader  @getUserinfo="getUserinfo"  :config="config" :nav="nav"></insideHeader>



    <main>

      <section class="l">

        <view class="search">

          <view class="search_input" v-click-outside="onClickOutside">
          <el-input
              v-model="countryKeyword"
              style="max-width: 600px"
              placeholder="请输入国家/地区"
              class="inputDeep"
              @focus="searchFocus"
              @blur="searchBlur"
              @change="searchChange"
              @input="searchInput"
          >
            <template #append>
              <el-button>
                <el-icon>
                  <Search />
                </el-icon>
              </el-button>
            </template>

          </el-input>
          </view>

          <view class="search_tabs" v-show="searchStart" >
            <el-tabs  @tab-change="areaClick" v-model="activeName" :tab-position="tabPosition" type="border-card" style="height:100%; width:500px">
              <el-tab-pane  v-for="(item,index) in area" :key="item" :label="item.label" :name="index">


                <el-badge @click.stop="courtyClick(item2)" class="courtyItem" value="new"  v-for="(item2,index2) in country" :key="index2">
                  <el-button>{{ item2.label }}</el-button>
                </el-badge>

              </el-tab-pane>

            </el-tabs>

          </view>
          <!-- search_tabs -->

          <view class="search_keyword" v-show="searchEnd" v-click-outside="onClickOutside2">
            <view class="search_keyword_content">

              <el-empty description="暂无相关内容" v-if="countryList.length==0" />

            <view @click="gotoVisa(item2.id)" class="search_keyword_item" v-for="(item2,index2) in countryList" :key="index2"><view class="cn">{{ item2.label }}</view> <view class="en">{{ item2.en }}</view></view>

            </view>
          </view>


        </view>
        <!-- search -->

        <view class="lLabel">
          <view class="lLabelLeft">
              <el-select v-model="labelId" placeholder="签证类别" clearable="true" @change="changeLabel">
              <el-option
                  v-for="item in label"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </view>
          <view class="lLabelRight">
            <el-select v-model="fangshiId" placeholder="签证形式" clearable="true" @change="changeFangshi">
              <el-option
                  v-for="item in fangshi"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </view>
        </view>
        <!-- lLabel -->

        <view class="lList">

          <ul  class="ul">
            <div class="el-loading-mask" style="display: none;">
              <div class="el-loading-spinner">
                <svg viewBox="25 25 50 50" class="circular">
                  <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
                </svg><!----></div></div>
               <el-empty description="暂无相关签证" v-if="visa.length==0" />

               <li @click="changeDetail(index2)" :class="active==index2?'listItemActive':''" v-for="(item2,index2) in visa" :key="index2">
              <h3 ><span>
                {{ item2.classifyData.label }}</span><b>{{ item2.title }}</b></h3><p>
              <time >有效期<b >{{item2.yxq}}</b>个月</time><strong>¥
              <span  class="price">{{ item2.price }}</span>起</strong></p></li>

          </ul>

        </view>

      </section>
      <!-- left -->

      <section class="r">

        <div class="rContent">
          <view class="rContentTitle">{{ detail.title }}</view>

          <view class="rContentInfo">
            <el-descriptions
                style="width: 100%;"
                title="属性"
                :column="2"
                :size="size"
                border
            >

              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <user />
                    </el-icon>
                    签证编码
                  </div>
                </template>
                {{ detail.no }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <iphone />
                    </el-icon>
                    送签方式
                  </div>
                </template>
                 <el-tag size="small" v-if="detail.fangshi==1">电子签</el-tag>
                <el-tag size="small" v-else-if="detail.fangshi==2">贴纸签</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <Timer />
                    </el-icon>
                    有效期(月)
                  </div>
                </template>

                <el-tag size="small">{{ detail.yxq }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <Timer />
                    </el-icon>
                    停留期(天)
                  </div>
                </template>
                <el-tag size="small">{{ detail.tlq }}</el-tag>
              </el-descriptions-item>

              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <UserFilled />
                    </el-icon>
                    是否面签
                  </div>
                </template>

                <el-tag size="small" type="danger" v-if="detail.mianqian">需要</el-tag>
                <el-tag size="small" v-else>不需要</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <WarnTriangleFilled />
                    </el-icon>
                    是否打指模
                  </div>
                </template>
                <el-tag size="small" type="danger" v-if="detail.zhimo">需要</el-tag>
                <el-tag size="small" v-else>不需要</el-tag>
              </el-descriptions-item>



              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <WarnTriangleFilled />
                    </el-icon>
                    综合出签率
                  </div>
                </template>
                {{detail.work[workActive].cql}}%
              </el-descriptions-item>


              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <Select  />
                    </el-icon>
                    已办理人数
                  </div>
                </template>
                {{detail.work[workActive].order}}
              </el-descriptions-item>

              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <WarnTriangleFilled />
                    </el-icon>
                    入境次数
                  </div>
                </template>
                {{detail.work[workActive].cishu}}次
              </el-descriptions-item>

              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <Timer />
                    </el-icon>
                    出签时长
                  </div>
                </template>
                {{detail.work[workActive].start_num}}--{{detail.work[workActive].end_num}} 工作日
              </el-descriptions-item>

              <el-descriptions-item width="140">
                <template #label>
                  <div class="cell-item">
                    <el-icon :style="iconStyle">
                      <office-building />
                    </el-icon>
                    常住地
                  </div>
                </template>
                <el-tag style="margin-right: 5px" size="small"  v-for="(item2,index2) in detail.czd" :key="index2">{{ item2 }}</el-tag>
              </el-descriptions-item>
            </el-descriptions>
          </view>
          <!-- rContentInfo-->

          <view class="rWork">
            <view class="rWorkTitle">套餐选择</view>

            <view class="rWorkInfo">

              <view @click="changeWork(index2)" class="rWorkInfoItem"  v-for="(item2,index2) in detail.work" :key="index2">
              <el-button :type="workActive==index2?'primary':'info'">{{ item2.title }}</el-button>
              </view>



              </view>


          </view>
          <!-- rWork-->


          <view class="rDate">
            <view class="rDateTitle">出行日期和份数</view>

            <view class="rDateInfo">

              <view class="rDateInfoD">
                <el-date-picker
                    v-model="date"
                    type="date"
                    placeholder="请选择出行日期"
                    :size="size"
                    :disabled-date="disabledDate"
                />
              </view>

              <view class="rDateInfoN">

                <el-input-number size="large" v-model="num" :min="1" :max="10" @change="changeNum" />
              </view>

            </view>


            <div  class="price">
              <p >

                <b><text>¥</text> {{ allPrice }}</b>

                <span ></span></p>
              <div  class="btn" @click="buy">
                <button >立即办理</button>
              </div>
            </div>

          </view>
          <!-- rDate-->

          <view class="rInfo" id="rInfo">
            <view class="rInfoTabs" id="target" :class="{ targetFixed: ifFixed }">


                <view :class="tabsActive==0?'tabsItemActive':'tabsItem'" @click="scrollTo(0)">
                 详细说明
                </view>
                <view :class="tabsActive==1?'tabsItemActive':'tabsItem'" @click="scrollTo(1)">
                  所需资料
                </view>
                <view :class="tabsActive==2?'tabsItemActive':'tabsItem'" @click="scrollTo(2)">
                  常住地
                </view>
                <view :class="tabsActive==3?'tabsItemActive':'tabsItem'" @click="scrollTo(3)">
                 样板展示
                </view>

                <view class="tabsItem" v-if="ifFixed">
                  <div  class="btn" @click="buy">
                    <button >立即办理</button>
                  </div>
                </view>


            </view>

          </view>
          <!-- rInfo-->

          <div class="content">

            <div class="contentItem">
              <div class="contentItemT">详细说明</div>
              <div class="contentItemC">
              <div v-html="detail.introduce"></div>
              </div>
            </div>


            <div class="contentItem">
              <div class="contentItemT">所需资料</div>
              <div class="contentItemC">

                <div class="contentItemCTabs">
                    <el-tabs v-model="activeZl" class="demo-tabs" @tab-click="changeZl">


                         <el-tab-pane :label="item2" :name="index2"   v-for="(item2,index2) in detail.doc_type" :key="index2">


                           <ul  class="data">


                             <li  v-for="(item,index) in zlList" :key="index">
                               <h5 >{{ item.label }}（x{{item.number}}）</h5>
                               <p>{{ item.remark }}</p>

                               <a v-if="item.fujian"  :href="item.fujian"  target="_blank">下载资料</a>
                             </li>

                           </ul>

                         </el-tab-pane>


                    </el-tabs>
                </div>

              </div>
            </div>



            <div class="contentItem">
              <div class="contentItemT">常住地</div>
              <div class="contentItemC">
                <el-tag style="margin-right: 5px" size="small"  v-for="(item2,index2) in detail.czd" :key="index2">{{ item2 }}</el-tag>
              </div>
            </div>



            <div class="contentItem">
              <div class="contentItemT">样板展示</div>
              <div class="contentItemC">
                <div v-html="detail.yangban"></div>
              </div>
            </div>


          </div>


        </div>

      </section>
    </main>

  <allFooter :config="config" :footer="footer"></allFooter>
</template>

<script>
import { ElMessage } from 'element-plus'
import insideHeader from "@/components/header/inside_header";
import allFooter from "@/components/footer/all_footer";
import { getConfig,getAd,getArea,getCountry,getVisa2,getDetail,getZl} from '@/config/api';

export default {
  name: "country",
  components:{
    insideHeader,
    allFooter

  },

  data() {
    return {
      nav: [],
      area: [],
      country: [],
      countryList:[],//搜索结果用
      visa:[],//签证产品列表
      config: {
        phone: '',
        open_time: ''
      },
      countryHot: [],
      footer: [],
      label:[],
      fangshi:[
        {label:'电子签','id':1},
        {label:'贴纸签','id':2}
      ],
      labelId:'',
      fangshiId:'',
      areaId: "",
      countryKeyword:'',
      countryId:'',
      tabPosition:'top',
      activeName:0,
      searchStart:false,
      searchEnd:false,
      size:'large',
      detail:{
        work:[{price:0}],
        doc_type:[]
      },
      detailId:'',
      active:0,
      workActive:0,
      tabsActive:0,
      ifFixed: false,// 是否吸顶
      targetTop: 0,// 目标元素与整个文档顶部间的距离
      over_targetTop: 0, //超过距离就取消吸顶
      num:1,
      date:'',
      minDate:'',
      allPrice:0,
      activeZl:0,
      nowSf:'',
      zlList:[],
      ids:[],//资料ID组
      nowList:[],
      title:'',
    }
  },

  mounted()
  {
    this.getConfigData()
    this.getAdData();
    this.getAreaData();
    window.addEventListener('scroll', this.onScroll, false)
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
    // 获取目标元素与整个文档顶部间的距离
    var swiperH = 157; //
    var view1H =614; //

    var view2H = document.querySelector("#rInfo").offsetHeight; //该元素父级高度
    var offsetH = document.querySelector("#target").offsetHeight; //该元素自身高度
    var offsetTop = document.querySelector("#target").offsetTop; //该元素自身距离当前父级顶部的距离
    this.targetTop = Number(swiperH) + Number(view1H);//当前元素的吸顶距离：该元素上方的页面高度之和
    this.over_targetTop =
        Number(swiperH) +
        Number(view1H) +
        (Number(view2H) - Number(offsetTop) - Number(offsetH)); //超过距离就取消吸顶

  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  created() {
     if(this.$route.query.country) {
       this.countryId = this.$route.query.country;
       this.getVisaData()
     }
     else {

       ElMessage({
         message:'参数错误',
         type: 'error',
         duration:2000,
         offset:200

       });

       setTimeout(() => {
         this.$router.push({
           path: '/'
         })
       }, 2000);

     }
  },
  methods:{
    buy()
    {
      if(this.allPrice<=0 || isNaN(this.allPrice))
      {
        ElMessage({
          message:'暂无报价',
          type: 'error',
          duration:2000,
          offset:200

        });
        return false;
      }

      let no= localStorage.getItem('no')
      if(!no || no=='')
      {
        ElMessage({
          message:'请先登录',
          type: 'error',
          duration:2000,
          offset:200

        });
        var url='/country?country='+this.countryId;
        url=encodeURIComponent(url);
        setTimeout(() => {

          this.$router.push('/login?jump='+url);
        }, 2000);
        return false;
      }

       url='/buy?work_id='+this.detail.work[this.workActive].id+'&num='+this.num+'&date='+this.date
      console.log('url',url);
      this.$router.push(url);
    },
    changeZl(e)
    {
       console.log('资料切换',e.props.label);
       this.nowSf=e.props.label;

       this.getZl();
    },
    getZl() {
      //资料列表
      let list = [];
      let ids = [];
      this.zlList=[];

      if (this.nowSf == '在职人员') list = this.detail.doc_list1
      else if (this.nowSf == '退休人员') list = this.detail.doc_list2
      else if (this.nowSf == '自由职业者') list = this.detail.doc_list3
      else if (this.nowSf == '学生') list = this.detail.doc_list4
      else if (this.nowSf == '学龄前儿童') list = this.detail.doc_list5
      else if (this.nowSf == '无业人员') list = this.detail.doc_list6

      for (var i = 0; i < list.length; i++) {
        ids = ids.concat(list[i].doc_id);
      }
      this.ids=ids;
      this.nowList=list;
      this.getZlData();
      console.log('资料ID',ids);
    },
    getAllPrice()
    {
         var price=this.num*this.detail.work[this.workActive].price;
         price=price.toFixed(2);
         this.allPrice=price;
    },
    disabledDate(date)
    {
      //限定日期最小日期
     // console.log(Date.parse(this.minDate));
     // if(date.getTime()=='1710720000000')
     // {
       // console.log('aaaaaaaaaaa');
     // }
      return date.getTime() < Date.parse(this.minDate);
    },
    changeWork(i)
    {
      this.workActive=i;

      const dd = new Date()
      var milliseconds = dd.getTime();


      // 假设我们想要增加 7 天
      var daysToAdd = this.detail.work[i].start_num;
      milliseconds += (daysToAdd * 24 * 60 * 60 * 1000);
      var milliseconds2=milliseconds-86400000;
      var d = new Date(milliseconds)
      const year = d.getFullYear()
      let month = d.getMonth() + 1
      month = month < 10 ? `0${month}` : month
      const date = d.getDate()

      var d2 = new Date(milliseconds2)
      const year2 = d2.getFullYear()
      let month2 = d2.getMonth() + 1
      month2 = month2 < 10 ? `0${month2}` : month2
      const date2 = d2.getDate()

      var mindate = `${year}-${month}-${date}`
      this.minDate = `${year2}-${month2}-${date2}`
      this.date = mindate;
      this.getAllPrice();
    },
    changeNum()
    {
       this.getAllPrice();
    },
    changeDetail(i)
    {
      this.active=i;
      this.getDetail()
    },
    scrolling() {
      // 获取当前滚动条向下滚动的距离
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      //console.log("scrollTop", scrollTop);
      console.log("this.targetTop", this.targetTop);
      if (scrollTop > this.targetTop) {
        this.ifFixed = true;
      } else {
        this.ifFixed = false;
      }
    },


    // 滚动监听器
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.content div')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      this.tabsActive = navIndex
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      console.log('targetOffsetTopindex',index);
      let targetOffsetTop = document.querySelector(`.content div:nth-child(${index + 1})`).offsetTop
      console.log('targetOffsetTop',targetOffsetTop);
      targetOffsetTop=parseFloat(targetOffsetTop)-160
      // 获取当前 offsetTop
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 50
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp()
      } else {
        // 往下滑
        smoothDown()
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown)
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    }

,
  courtyClick(e)
    {
      console.log(e);
      this.countryKeyword=e.label;
      this.countryId=e.id;
      this.searchStart=false;
      this.getVisaData();
    },
    areaClick(e)
    {
      console.log(e);
    },
    searchChange()
    {
      console.log('Change');
    },
    searchInput()
    {
       //搜索框关键词搜索

       if(this.countryKeyword!='') {
         this.searchEnd=true;
         this.searchStart=false;
         this.getCountryList();
       }
       else {
         this.searchEnd=false;
         this.searchStart=true;
       }
    },
    searchFocus()
    {
      //输入框开始
       this.searchStart=true;
    },
    onClickOutside()
    {
      console.log('out');
      this.searchStart=false;
      this.searchEnd=false;
    },
    onClickOutside2()
    {

      this.searchEnd=false;
    },
    searchBlur()
    {
     // this.searchStart=false;
    },
    async  getAreaData()
    {
      var param={

      }
      const res = await getArea(param);
      if(res.code==200)
      {
        this.area=res.data.rows;
        this.areaId=this.area[0].id

        this.getCountry();

      }
    },
    async  getCountry(id='')
    {
      //弹窗用
      var top='';
      if(id) top=id
      else top=this.areaId
      var param={
        top:top,
        pageSize:50
      }
      const res = await getCountry(param);
      if(res.code==200)
      {
        this.country=res.data.rows;

      }
    },
    async  getCountryList()
    {
      //国家列表用

      var param={
        keyword:this.countryKeyword,
        pageSize:50,
        loading:1  // 指定不加载  loading
      }
      const res = await getCountry(param);
      if(res.code==200)
      {
        this.countryList=res.data.rows;

      }
    },
    async  getAdData()
    {
      var param={
        ident:'index_swiper|index_nav|footer|label'
      }
      const res = await getAd(param);
      if(res.code==200)
      {
        this.banner=res.data.rows.index_swiper
        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
        this.label=res.data.rows.label
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        this.title =this.config.title;

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }
    },
    changeFangshi()
    {
      this.visa=[];
      this.getVisaData();
    },
    changeLabel()
    {
      this.visa=[];
      this.getVisaData();
    },
    gotoVisa(id)
    {
      this.countryId=id;
      this.visa=[];
      this.searchEnd=false;
      this.getVisaData();
    },
    async getVisaData()
    {
      //签证产品列表
      var param={
        country:this.countryId,
        labelId:this.labelId,
        fangshiId:this.fangshiId,
        loading:1  // 指定不加载  loading
      }
      const res = await getVisa2(param);
      if(res.code==200)
      {
        this.visa=res.data.rows;

        if(this.visa.length>0)   this.getDetail();
      }
    },
    async getDetail()
    {
      //签证详情
      var param={
        id:this.visa[this.active].id,
        loading:1  // 指定不加载  loading
      }
      const res = await getDetail(param);
      if(res.code==200)
      {
        this.detail=res.data.rows;
        document.title=this.detail.title+'-'+this.title;
        if(this.detail.work[0])
        {
          this.getAllPrice();
          this.nowSf=this.detail.doc_type[this.activeZl];
          this.getZl();
          const dd = new Date()
          var milliseconds = dd.getTime();

          // 假设我们想要增加 7 天
          var daysToAdd = this.detail.work[0].start_num;
          milliseconds += (daysToAdd * 24 * 60 * 60 * 1000);
          var milliseconds2=milliseconds-86400000;
          var d = new Date(milliseconds)
          const year = d.getFullYear()
          let month = d.getMonth() + 1
          month = month < 10 ? `0${month}` : month
          const date = d.getDate()

          var d2 = new Date(milliseconds2)
          const year2 = d2.getFullYear()
          let month2 = d2.getMonth() + 1
          month2 = month2 < 10 ? `0${month2}` : month2
          const date2 = d2.getDate()

          var mindate = `${year}-${month}-${date}`
          this.minDate = `${year2}-${month2}-${date2}`
          this.date = mindate;
        }
        else {

          this.allPrice='暂无报价';
        }

      }
    },
    async getZlData()
    {
      //所需资料
      var param={
        ids:JSON.stringify(this.ids),
        loading:1  // 指定不加载  loading
      }
      const res = await getZl(param);
      if(res.code==200)
      {
        this.zlList=res.data.rows;
        for(var i=0;i<this.zlList.length;i++)
        {
          for(var a=0;a<this.nowList.length;a++)
          {
            if(this.zlList[i].id==this.nowList[a].doc_id)
            {
              this.zlList[i].remark=this.nowList[a].remark;
              this.zlList[i].number=this.nowList[a].number;
            }
          }
        }

      }

    }




  }
}
</script>

<style scoped>

@media only screen and (max-width: 450px) {
  .footer_box figure[data-v-3c0c57b2] {
    padding:0 10px
  }

  .footer_box .bottom[data-v-3c0c57b2] {
    padding: 10px
  }
}

@media only screen and (max-width: 3840px) {
  #app>main {
    width:1200px;
    margin: 0 auto
  }
}

@media only screen and (max-width: 1024px) {
  #app>main {
    width:768px;
    padding: 0 2%
  }
}

@media only screen and (max-width: 768px) {
  #app>main {
    width:100%
  }
}
.page {
  background:#fafafa;
}
main{
  width: 100%;

}

main .l {
  width: 300px;
  flex: none;
  overflow-x: visible;
  /*position: fixed;*/
  position: absolute;
  left: 50%;
  top: 177px;
  transform: translateX(-600px);
  z-index: 1
}
main .r{
  width: 100%;
  max-width: 900px;
  margin: 20px auto 0;
  margin-left: 320px;
}

.search{
  position: relative;
}
.search_tabs{
  position: absolute;
  left: 0px;
  top:42px;
  z-index: 100;
}
.search_keyword{
  position: absolute;
  left: 0px;
  top:42px;
  z-index: 100;
  background: #fff;
  width: 100%;
  border:1px solid #eee;
}
.search_keyword_content{
  width: 100%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.search_keyword_item{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  cursor: pointer;
}
.search_keyword_item .cn{
  font-weight: bold;
}
.search_keyword_item .en{
  color:#999
}


.courtyItem{ margin-right: 30px;}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}


.search_input {

:deep(.el-input__wrapper) {

  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 40px;
}

:deep(.el-input-group__append) {

  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;;
  height: 42px;

}
}

.lLabel{ width: 100%; display: flex; margin-top: 20px}
.lLabelLeft{ width: 45%;; margin-right: 20px;}
.lLabelRight{ width: 45%;}
ul{
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.lList{
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.06);
  height: 500px;
  overflow-y: scroll;
  display: flex;
  margin-top: 20px;
}

.lList .l ul:empty:before {
  display: block;
  content: "";
  padding: 10px;

}

.l ul li {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #e9eef2;
  cursor: pointer;
  list-style-type: none;
}

 .l ul li.listItemActive,main .l ul li:hover {
  background-color: rgba(89,159,255,.12)
}

.l ul li.listItemActive:before,main .l ul li:hover:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;

}

.l ul li.listItemActive h3 b,main .l ul li:hover h3 b {
  font-weight: 700
}

.l ul li h3 {
  width: 100%;
 font-size: 14px;
  color: #333;
  line-height: 1.4
}

.l ul li h3 span {
  display: inline-block;
  padding: 3px 5px 2px;
  color: #fff;
  flex: none;
  background: linear-gradient(135deg,#7bb3f2,#5494dd);
  border-radius: 5px;
  margin: 0 5px 0 0;
  font-size: 12px;
}

 .l ul li p {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px 0 0;
   font-size: 12px;
  color: #999;
   margin: 0px;
   padding: 0px;
}

 .l ul li p b {

  font-weight: 700;
  padding: 0 3px
}

.l ul li p strong{
  padding: 0 10px 0 0;
  color:#f56c6c;

}

 .l ul li p strong .small {
  padding: 0 10px 0 0
}

.l ul li p strong .price {

  font-weight: 500;
  font-size: 16px;
}





 .l ul li p strong .ull {
  display: flex;
  align-items: center;
  margin: 20px 0 0 -10px
}

.l ul li p strong .ull li {

  border-right: 1px solid #f5f5f5;
  padding: 0 20px 0 10px
}

 .l ul li p strong .ull li:last-of-type {
  border-right: none
}

 .l ul li p strong .ull li b {
  display: block;
  padding: 8px 0 0
}

.rDate .price p b text{
 font-size: 16px;
}


.rContent{
   width: 100%;
 }
.rContentTitle{
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.rContentInfo{
  margin-top: 40px;
  display: flex;
  width: 100%;
}

.rWork{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.rWorkTitle{
  width: 100%;
  display: flex;
  margin: 30px 0px 20px 0px;
  font-size: 16px;
  font-weight: bold;
}
.rWorkInfo{
  display: flex;
  flex-wrap: wrap;
}
.rWorkInfoItem{
  width: 100%;
  margin-bottom: 10px;
}

.rDate{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.rDateTitle{
  width: 100%;
  display: flex;
  margin: 30px 0px 20px 0px;
  font-size: 16px;
  font-weight: bold;
}
.rDateInfoN{
  margin-left: 30px;
}
.rInfo{
  margin-top: 30px;
  display: flex;
  width: 100%;

}
.rInfoTabs{
  width: 100%;
  display: flex;
  max-width:880px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.06);
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  .tabsItemActive{
     width: 20%;
     height: 60px;
     line-height: 60px;
     font-size: 14px;
     font-weight: bold;
    cursor: pointer;
    }
  .tabsItem{
    width: 20%;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    }
}

.rDate .price{
  margin: 20px 0 0;
  background: linear-gradient(90deg,rgba(51,112,255,.04),rgba(204,243,255,.04));
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
}
.rDate button {
  border: none;
}
.rDate .price p {
  font-size:12px;
  color: #909399;
}

.rDate .price p b {
  display: block;
  font-size: 32px;
  font-weight: 700;
  color: #f56c6c;
  padding: 0 0 5px
}



.rDate .price .btn button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f56c6c;
  width: 120px;
  height: 40px;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

/*
.targetFixed {
  position: fixed;
  top: 156px;
  z-index: 100;
}

 */

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.06);
  margin-top: 20px;
}
.contentItem{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

}
.contentItemT {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  border-bottom: 1px solid #f2f6fc;
  padding: 20px;
}
.contentItemC{
  padding: 20px;
  width: 95%;
}

::v-deep .contentItemC img{
  width: auto;
  max-width: 100%;
}

.contentItemCTabs{
  width: 100%;
}
.tabsItem .btn button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f56c6c;
  width: 120px;
  height: 40px;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border: 0px;
}

.data ul{
  margin: 0px;
  padding: 0px;
}
.data li {
  position: relative;
  padding: 20px 300px 20px 0;
  margin: 0 0 0 20px;
  border-bottom: 1px solid #f2f6fc;
  list-style-type: none;
  width: 70%;
}

.data li h5 {
  font-size: 16px;
  font-weight: 700;
  color: #303133;;
  padding: 0 0 10px;
  margin: 0;
}

.data li p {
  overflow: hidden;
  white-space: pre-wrap;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.data li p img{
  max-width: 100%
}

.data li a {
  position: absolute;
  right: 85px;
  top: 50%;
  transform: translateY(-50%);
  color: #3d92d7;
  border: 1px solid #3d92d7;;
  padding: 10px 25px;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer
}

.data li a:hover {
  background-color: #3d92d7;;
  color: #fff
}

@media only screen and (max-width: 1200px) {
  main {
    display:flex;
    flex-direction: column;
    box-sizing: border-box
  }

  main .l {
    width: 96%;
    margin: 20px auto;
    position: relative;
    left: unset;
    top: unset;
    transform: translate(0)
  }

  main .r {
    width: 96%;
    margin: 20px auto;
    max-width: 100%;
    transform: translate(0)
  }

  main .time_lines {
    max-width: 100%;
    box-sizing: border-box
  }

  main .time_line {
    left: 0;
    transform: translate(0);
    max-width: unset;
    margin-left: 0
  }
}

@media only screen and (max-width: 768px) {
  main .r section .data li {
    padding:20px 0 20px 0;
    box-sizing: border-box
  }

  main .r section .data li a {
    position: relative;
    transform: translate(0);
    right: unset!important;
    top: unset;
    left: unset;
    display: inline-block;
    margin: 10px 10px 0 0
  }

  main .r section .subTitle {
    justify-content: space-around
  }

  main .r section .subTitle span {
    padding: 0 1%
  }

  main .l ul {
    max-height: 380px
  }
}

@media only screen and (max-width: 480px) {
  main .r {
    width:98%
  }

  main .r header ul {
    flex-wrap: wrap
  }

  main .r header ul li {
    padding: 10px 20px
  }

  main .r section .subTitle {
    justify-content: space-around
  }

  main .r section .subTitle span {
    padding: 0
  }

  main .l ul {
    max-height: 230px
  }
}
</style>
